import React from "react";
import styled from "styled-components"
import "react-image-gallery/styles/css/image-gallery.css";
import '../card.css';
import ProductCard from "./ProductCard";
import {tpArr, centerpullArr, dispenserArr} from './ProductDetails';
import button1 from "../../images/buttons/buy-button.png"

const tpItems = tpArr.map(function(image) {
    return {original: image, thumbnail: image}
})

const centerpullItems = centerpullArr.map(function(image) {
    return {original: image, thumbnail: image}
})

const dispenserItems = dispenserArr.map(function(image) {
    return {original: image, thumbnail: image}
})

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`

const CountDisplay = styled.h2`
    font-family: 'Karla';
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #4d4d4f;
    letter-spacing: 0px;
    margin-top: 50px;
`

const numProductsDisplayed = 2;

const ProductCardSection = () => {
    return (
        <>
            <CountDisplay>Viewing {numProductsDisplayed} of {numProductsDisplayed} products</CountDisplay>
            <Container>
                <ProductCard
                    items={tpItems}
                    type="Toilet paper"
                    name="SOFT + STRONG BIGGER ROLLS"
                    description={<>Soft, strong premium bath tissue that gives you greater value on consumer spending 
                    than most leading brands. Without the lint!</>}
                    details="12 pack option"
                    button={button1}
                    buttonLink="/wheretobuy"
                />
                <ProductCard
                    items={centerpullItems}
                    type="Paper towels"
                    name="SOFT + ABSORBENT GIANT ROLLS"
                    description={<>HUGE multi-purpose rolls to save you from all of your messy clean ups. Clean up smart 
                    with quality towels that will give you the best bang for your buck.</>}
                    details="12 pack option"
                    button={button1}
                    buttonLink="/wheretobuy"
                />
                {/* <ProductCard
                    items={dispenserItems}
                    type="Paper towels"
                    name="SOFT + ABSORBENT GIANT ROLLS"
                    description={<>HUGE multi-purpose rolls to save you from all of your messy clean ups. Clean up smart 
                    with quality towels that will give you the best bang for your buck.</>}
                    details="12 pack option"
                    button={button1}
                    buttonLink="/wheretobuy"
                /> */}
            </Container>
        </>
    )
}

export default ProductCardSection


