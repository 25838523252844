import tp1 from "../../images/products/product-tp/tp-product.png"
import tp2 from "../../images/products/product-tp/tp-feature-1.png"
import tp3 from "../../images/products/product-tp/tp-feature-2.png"
import tp4 from "../../images/products/product-tp/tp-feature-3.png"
import tp5 from "../../images/products/product-tp/tp-feature-4.png"

import centerpull1 from "../../images/products/product-centerpull/cp-product.png"
import centerpull2 from "../../images/products/product-centerpull/cp-feature-1.png"
import centerpull3 from "../../images/products/product-centerpull/cp-feature-2.png"
import centerpull4 from "../../images/products/product-centerpull/cp-feature-3.png"

import dispenser1 from "../../images/products/product-dispenser/dispenser-product.png"

export const tpArr = [
    tp1,
    tp2,
    tp3,
    tp4,
    tp5
];

export const centerpullArr = [
    centerpull1,
    centerpull2,
    centerpull3,
    centerpull4
];

export const dispenserArr = [
    dispenser1
];

export default {
    tpArr,
    centerpullArr,
    dispenserArr
}