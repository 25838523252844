import React from "react"
import styled from "styled-components"
import ProductCardSection from "./ProductCardSection"
import AboutProducts from "./AboutProducts"

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`

const ProductContent = () => {
    return (
        <Container>
            <ProductCardSection/>
            <AboutProducts/>
        </Container>
    )
}

export default ProductContent