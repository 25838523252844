import React from "react"
import Layout from "../components/layout"
import PageBanner from "../components/PageBanner"
import ProductContent from "../components/Products/ProductContent"
import { Helmet } from "react-helmet"


const ProductsPage = () => (
  <>
    <Helmet>
      <title>Products | Maxie Paper</title>
      <meta charSet="utf-8" />
      <meta name="description" content=""/>
      <meta name="keywords" content="Maxie, Maxie Needs, Toilet Paper"/>
    </Helmet>
    <Layout>
      <PageBanner title="Products" description="Browse our Collection"/>
      <ProductContent/>
    </Layout>
  </>
)

export default ProductsPage
