import React from "react";
import styled from "styled-components"
import ImageGallery from "react-image-gallery";
import { Link } from "gatsby"
import "react-image-gallery/styles/css/image-gallery.css";
import '../card.css';

const Container = styled.div`
    display: flex;
    flex-wrap: row;
    align-items: center;
    width: 65%;
    background-color: #eff9fe;
    border: 40px solid #eff9fe;
    border-radius: 25px;
    margin: 50px;

    @media (max-width: 1000px) { width: 80%; }
    // @media (max-width: 800px) { width: 50%; }
    @media (max-width: 700px) { flex-flow: column wrap; width: 60%;}
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 20px 20px 20px 30px;
    text-align: left;

    @media (max-width: 700px) { margin: 20px; width: 80%; text-align: center; }
`

const Type = styled.h3`
    font-family: 'Karla';
    font-style: italic;
    font-weight: 700;
    -webkit-text-stroke: 0.5px #4580b2;
    font-size: 23px;
    color: #4580b2;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-bottom: 5px;

    @media (max-width: 1000px) { font-size: 20px; }
    @media (max-width: 800px) { font-size: 18px; }
`

const Name = styled.h2`
    font-family: 'Karla';
    font-weight: 700;
    -webkit-text-stroke: 0.5px #4d4d4f;
    font-size: 33px;
    color: #4d4d4f;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-bottom: 20px;

    @media (max-width: 1000px) { font-size: 30px; }
    @media (max-width: 800px) { font-size: 27px; }
`

const Description = styled.span`
    font-family: 'Karla';
    font-style: semi-bold;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #4d4d4f;
    letter-spacing: 0px;
    margin-bottom: 20px;

    @media (max-width: 1000px) {
        font-size: 16px;
        line-height: 19px;
    }

    @media (max-width: 800px) {
        font-size: 14px;
        line-height: 17px;
    }
`

const Details = styled.h2`
    font-family: 'Karla';
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #4d4d4f;
    letter-spacing: 0px;
    margin-bottom: 20px;

    @media (max-width: 800px) {
        font-size: 13px;
        line-height: 16px;
    }
`

const Button = styled.img`
    width: 120px;
    opacity: 1;

    :hover {
        opacity: 0.9;
    }

    @media (max-width: 800px) {
        width: 100px;
    }
`

const properties = {
    thumbnailPosition: "bottom",
    useBrowserFullscreen: false,
    showFullscreenButton: false,
    showPlayButton: false,
    showNav: false
};

const ProductCard = (props) => {
    return (
        <Container>
            <ImageGallery {...properties} items={props.items} style={{height: 50}}/>
            <InnerContainer>
                <Type>{props.type}</Type>
                <Name>{props.name}</Name>
                <Description>{props.description}</Description>
                <Details>{props.details}</Details>
                <Link to={props.buttonLink} style={{textDecoration: "none"}}>
                    <Button src={props.button}/>
                </Link>
            </InnerContainer>
        </Container>
    )
}

export default ProductCard

