import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SectionTitle from "../SectionTitle";

const aboutInfo = [
    {
        icon: "about_icon-17.png",
        description: "No added Optical Brightener or Fluorescent Additives"
    },
    {
        icon: "about_icon-18.png",
        description: "Ultra Soft and Strong"
    },
    {
        icon: "about_icon-19.png",
        description: "Ultra High Temp Sterilized"
    },
    {
        icon: "about_icon-20.png",
        description: "100% Virgin Pulp"
    },
    {
        icon: "about_icon-21.png",
        description: "Supply from controlled & responsible sources"
    },
  ];

const Container = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    width: 500px;
    background-color: #649acb;
    border: 40px solid #649acb;
    border-top: 55px solid #649acb;
    border-bottom: 55px solid #649acb;
    border-radius: 25px;
    margin: 50px;

    @media (max-width: 700px) { width: 80%; }

`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    text-align: left;
`

const Icon = styled.img`
    height: 50px;
    margin: 20px;
`

const Description = styled.span`
    font-family: 'Karla';
    font-style: semi-bold;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #eff9fe;
    letter-spacing: 0.4px;
`

const AboutProducts = (props) => {
    return (
        <>
            <SectionTitle title="About Our Products"/>
            <Container> 
                {aboutInfo.map((info) =>
                    <InnerContainer>
                        <Icon src={require("../../images/products/about_icons/"+info.icon)}/>
                        <Description>{info.description}</Description>
                    </InnerContainer>
                )}
            </Container>
        </>
    )
}

export default AboutProducts